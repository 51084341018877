//
// Card
//

.card {
  background-size: cover;
  background-position: center center;
  box-shadow: 0 20px 70px 0 rgba(0, 0, 0, .15);
  overflow: hidden;
  margin-bottom: $spacer;
}

.card-header,
.card-footer {
  background: none;
}
.card-header {
  border-bottom: 1px solid $border-color;
}

//
// Alternate cards
//

.card {
  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      @include card-variant($value);
    }
  }
}


//
// Card + JS Tilt Plugin
//

.js-tilt {
  transform-style: preserve-3d;

  .card-body,
  .card-header,
  .card-footer {
    z-index: 1;
  }

  &:hover {
    &::after {
      opacity: .7;
    }
    .card-body,
    .card-header,
    .card-footer {
      transform: translateZ(20px);
    }
  }

  &::after {
    position: absolute;
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    transition:  all .7s cubic-bezier(0.25, 1, 0.33, 1);
  }
}

//
// Card sizes
//

.card-sm {
  .card-body {
    padding: $spacer / 2;
  }
  .card-header,
  .card-footer {
    padding: 1rem ($spacer / 2);
  }
}

@include media-breakpoint-up(xl) {

  .card-xl {
    .card-body {
      padding: $spacer * 2.8;
    }
    .card-header,
    .card-footer {
      padding: 2rem ($spacer * 2.8);
    }
  }

}

@include media-breakpoint-up(lg) {

  .card-lg {
    .card-body {
      padding: $spacer * 2.2;
    }
    .card-header,
    .card-footer {
      padding: 1rem ($spacer * 2.2);
    }
  }

}