// Vieport Height
@for $i from 1 through 10 {
  .vh-#{$i} {
    height: 10vw * $i
  }
}

@include media-breakpoint-down(sm) {
  @for $i from 1 through 10 {
    .vh-sm-#{$i} {
      height: 10vw * $i
    }
  }
}