//
// Pie charts
//

.pie-chart {
  position: relative;
  text-align: center;
  padding: 40px 0;

  .chart {
    position: relative;
    display: block;
    height: $pie-size;
    width: $pie-size;
    margin: 0 auto 28px;
    text-align: center;
    border-radius: 50%;

    > canvas {
      position: absolute;
      left: 0;
      top: 0;
      height: 100% !important;
      width: 100% !important;
    }
  }

  .chart-text {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    line-height: $pie-size;

    span {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      border-radius: 100%;
      width: 54px;
      height: 54px;
      line-height: 54px;
      font-size: 20px;
      background-color: #313131;
      color: #fff;
    }
  }
}

