//
// Social icons
//

.social-icons {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    display: inline-block;
    margin: 0 rem(20) 0 0;

    > a {
      font-size: rem(14);
      color: $headings-color;
    }
  }
}

.social-icons > li > a:hover,
.social-icons > li > a:focus {
  color: $link-color;
}

.social-icons.text-center > li,
.text-center .social-icons > li {
  margin: 0 rem(10) 0 rem(10);
}

.social-icons.text-right > li,
.text-right .social-icons > li {
  margin: 0 0 0 rem(20);
}
