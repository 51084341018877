//
// Progress Bars
//

.progress-item {
   margin-bottom: 20px;

  & + & {
    margin-top: 24px;
  }
}

.progress-title {
  margin-bottom: 8px;
}

.progress {
  border-radius: $border-radius;
}