//
// Pricing
//

.price-table {
  border: 1px solid $border-color;
  background: $body-bg;

  .price-table-header {
    background: rgba($border-color, .2);
    padding: rem(30px) rem(40px);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      border-top: 1px solid $border-color;
      padding: rem(12px) rem(40px);
    }
  }
}

.price-table-popular .price-table-header {
  &::after {
    position: absolute;
    top: -5px;
    left: 0;
    height: 5px;
    width: 100%;
    content: "";
    display: block;
    background: theme-color("brand");
  }
}