//
// Scroll Top
//

.scroll-top {
  position: fixed;
  display: block;
  bottom: $srcoll-top-position;
  right: $srcoll-top-position;
  z-index: 999;
  background: rgba(color("white"), .9);
  height: $srcoll-top-size;
  width: $srcoll-top-size;
  line-height: $srcoll-top-size - rem(2px);
  text-align: center;
  font-size: $srcoll-top-font-size;
  border-radius: $srcoll-top-border-radius;
  box-shadow: 0 0 0px 5px rgba(0, 0, 0, .05);
  transition: .5s all ease;
  transform: translateY($srcoll-top-position + $srcoll-top-size + rem(5px));
  color: $body-color;

  > i {
    line-height: $srcoll-top-size - rem(2px);
  }

  &.scroll-top-visible {
    transform: translateY(0);
  }

  &:hover {
    box-shadow: 0 0 0px 5px rgba(0, 0, 0, .07);
    transform: translateY(rem(-5px));
  }
}

@include media-breakpoint-down(sm) {

  .scroll-top {
    display: none;
  }

}