//
// Colors
//

@mixin bg-text-variant($background) {
  color: rgba(color-yiq($background), .8);

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6,
  blockquote,
  .blockquote-footer,
  .counter .counter-icon,
  .icon-box .icon-box-icon {
    color: color-yiq($background);
  }

  a:not(.btn):not(.accordion-link):not(.alert-link) {
    color: color-yiq($background);

    &:hover,
    &:focus {
      color: rgba(color-yiq($background), .7);
    }
  }

  .breadcrumb-item.active,
  .breadcrumb-item + .breadcrumb-item::before {
    color: rgba(color-yiq($background), .6);
  }

  .owl-carousel .owl-dots .owl-dot:hover,
  .owl-carousel .owl-dots .owl-dot.active {
    border-color: color-yiq($background);
  }

  .owl-carousel .owl-dots .owl-dot > span {
    background: color-yiq($background);
  }

  .wave-pulse:after,
  .wave-pulse:before {
    border-color: rgba(color-yiq($background), .15);
  }
}